
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, QuotationDetailCard } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import { addComma } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import SalesConversionRateBarChart from '@/components/reports-v2/components/codedWidgets/sales/SalesConversionRateBarChart.vue';

@Component({
	components: {
		TableFilterable,
    SalesConversionRateBarChart,
	},
})
export default class QuotationTrackingTable extends FilterWidget {
	@Prop({ default: () => [] }) public selectedAreas!: string[];

	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'quotations'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public validityDays: number = 60;

	public tableItems: Array<{
		agent: string;
		quote_count: number;
		total_quoted_amt: number;
		converted_quote_count: number;
		open_quote_count: number;
		converted_amount: number;
		conversion_rate: number;
		items: any;
	}> = [];
	public tableFields: any[] = [];
	public detailFields: any[] = [];
	public detailFields2: any[] = [];

	public isCalculateGrouping: number = 0;

	public infoModal = {
		id: 'info-modal',
		index: 0,
		title: '',
    extras: '',
		amount: '',
		items: [] as any[],
	};

	public format(
		value: number,
		decimal: number = 2,
		forceDecimal: boolean = true,
	) {
		return addComma(value, decimal, forceDecimal);
	}

	public generateGrouping() {
		this.isCalculateGrouping += 1;
	}

	public info(item, extras, index, title, amount, link) {
		this.infoModal.title = title;
  this.infoModal.extras = extras;
		this.infoModal.index = index;
		this.infoModal.amount = this.format(amount);
		this.infoModal.items = item.items;
		this.$root.$emit('bv::show::modal', this.infoModal.id, link);
	}

	public resetInfoModal() {
		this.infoModal.title = '';
  this.infoModal.extras = '';
		this.infoModal.items = [];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Open Quotation Tracking' + '_' + 'As Of ' + formattedDate;
	}

	public get dateFormatted() {
		return [this.selectedDateRange];
	}

	public get expensiveHook() {
		const {
			selectedAgents,
			isCalculateGrouping,
			selectedAsOfDate,
      selectedDateRange,
			selectedCustomers,
			selectedAreas,
		} = this;
		return JSON.stringify([
			selectedAgents,
			isCalculateGrouping,
			selectedAsOfDate,
      selectedDateRange,
			selectedCustomers,
			selectedAreas,
		]);
	}

	public async expensiveCalc() {
		const globalCustomerRef = FilteredDatabase.ref('globalCustomers')
			.includes('area', this.selectedAreas)
			.customers(this.selectedCustomers);

		const filteredAreaCustomers = [
			...(await (await globalCustomerRef.get()).getSet('code')),
		];

		const quotationRef = FilteredDatabase.ref('quotations')
			.agents(this.selectedAgents)
			.customers(
				filteredAreaCustomers.length > 0
					? filteredAreaCustomers
					: this.selectedCustomers,
			)
			.dateRange(this.selectedDateRange);

		const quotationCards: QuotationDetailCard[] = await (
			await quotationRef.get()
		).getCards();

		const groupedAgent = {};

		for (const item of quotationCards) {
			if (moment().diff(moment(item.date), 'days') <= this.validityDays) {
				if (!groupedAgent[item.agent]) {
					groupedAgent[item.agent] = [];
				}
				groupedAgent[item.agent].push(item);
			}
		}

		const beforeResult: any[] = [];

		for (const item in groupedAgent) {
			if (groupedAgent.hasOwnProperty(item)) {
				const object = {
					agent: '',
					quote_count: 0,
					total_quoted_amt: 0,
					converted_quote_count: 0,
					open_quote_count: 0,
					converted_amount: 0,
					conversion_rate: 0,
					items: [] as any[],
				};

				const cards = groupedAgent[item] as QuotationDetailCard[];

				const groupedDocNo = {};

				for (const i of cards) {
					if (!groupedDocNo[i.docNo]) {
						groupedDocNo[i.docNo] = [];
					}
					groupedDocNo[i.docNo].push(i);
				}

				// const cards2 = cards.filter(
				// 	(card) => card.quantity === card.outstandingQuantity,
				// );

				// const detailResult = {};

				// for (const detailItem of cards2) {
				// 	if (!detailResult[detailItem.docNo]) {
				// 		detailResult[detailItem.docNo] = [];
				// 	}
				// 	detailResult[detailItem.docNo].push(detailItem);
				// }

				const itemObject: Array<{
					open_quote_doc_no: string;
          extras: string;
					date: number;
					company: string;
					amount: number;
					items: any[];
				}> = [];

				const convertedCases: QuotationDetailCard[] = [];
				const openCases: QuotationDetailCard[] = [];

				for (const docNo in groupedDocNo) {
					if (groupedDocNo.hasOwnProperty(docNo)) {
						const docNoCards = groupedDocNo[docNo] as QuotationDetailCard[];

						const totalOSQty = docNoCards.reduce(
							(a, b) => a + b.outstandingQuantity,
							0,
						);

						const totalQty = docNoCards.reduce((a, b) => a + b.quantity, 0);

						if (totalOSQty === totalQty) {
							openCases.push(...docNoCards);
						} else {
							convertedCases.push(...docNoCards);
						}
					}
				}

				const groupedOpenCase = {};

				for (const j of openCases) {
					if (!groupedOpenCase[j.docNo]) {
						groupedOpenCase[j.docNo] = [];
					}
					groupedOpenCase[j.docNo].push(j);
				}

				for (const openDocNo in groupedOpenCase) {
					if (groupedDocNo.hasOwnProperty(openDocNo)) {
						const openCards = groupedOpenCase[
							openDocNo
						] as QuotationDetailCard[];

						const detailObject = {
							open_quote_doc_no: '',
              extras: '',
							date: 0,
							company: '',
							amount: 0,
							items: [] as any[],
						};

						detailObject.open_quote_doc_no = openDocNo;
						detailObject.extras = `- ${moment(openCards[0].date).format('DD/MM/YYYY')}
						- ${gdbx.customerNames[openCards[0].code]}
						- ${item}`;
						detailObject.date = openCards[0].date;
						detailObject.company = gdbx.customerNames[openCards[0].code];
						detailObject.amount = openCards
							.map((card) => card.amount)
							.reduce((a, b) => a + b, 0);
						detailObject.items = openCards.map((i) => ({
							sequence: i.sequence / 1000,
							item: gdbx.stockItemNames[i.itemCode],
              unit_price: i.amount / i.quantity ? i.amount / i.quantity : 0,
              discount: Math.ceil(i.discount),
							quantity: i.quantity,
							sub_total: i.amount,
						}));

						itemObject.push(detailObject);
					}
				}

				const amount = cards
					.map((card) => card.amount)
					.reduce((a, b) => a + b, 0);

				const convertedAmount = cards
					.map((card) => card.transferredAmt)
					.reduce((a, b) => a + b, 0);

				const convertedCaseCount = [
					...new Set(convertedCases.map((card) => card.docNo)),
				].length;

				const openCaseCount = [...new Set(openCases.map((card) => card.docNo))]
					.length;

				const caseCount = [...new Set(cards.map((i) => i.docNo))].length;

				object.total_quoted_amt = amount;
				object.converted_amount = convertedAmount;
				object.open_quote_count = openCaseCount;
				object.agent = item;
				object.quote_count = caseCount;
				object.converted_quote_count = convertedCaseCount;
				object.conversion_rate = (convertedCaseCount / caseCount) * 100;
				object.items = itemObject;

				beforeResult.push(object);
			}
		}

		this.tableItems = [
			...beforeResult.filter((item) => item.open_quote_count > 0),
		];

		// this.tableItems = this.tableItems.filter(
		// 	(item) => item.open_quote_count !== 0,
		// );

		this.tableFields = [
			{ key: 'agent', sortable: true },
      {
        key: 'open_quote_count',
        label: 'Open Quote',
        sortable: true,
      },
      {
        key: 'conversion_rate',
        label: 'Conversion (Case)',
        sortable: true,
        formatter: TableItemFormatter.percentage,
      },
      {
        key: 'quote_count',
        label: 'Total Quote',
        sortable: true,
      },
      {
        key: 'converted_quote_count',
        label: 'Converted Quote',
        sortable: true,
      },
			{
				key: 'total_quoted_amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},

			{
				key: 'converted_amount',
				label: 'Converted Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},


		];

		this.detailFields = [
			{
				key: 'open_quote_doc_no',
				label: 'Doc No',
				sortable: true,
			},
			{
				key: 'date',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'company',
				sortable: true,
			},
			{
				key: 'amount',
				label: 'Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
		];

		this.detailFields2 = [
			{ key: 'sequence', label: 'Seq', sortable: true },
			{ key: 'item', sortable: true },
			{
				key: 'quantity',
				label: 'Qty',
				sortable: true,
			},
      {
				key: 'unit_price',
				label: 'Qty',
				sortable: true,
        formatter: TableItemFormatter.currency,
			},
      {
				key: 'discount',
				label: 'Qty',
				sortable: true,
			},
			{
				key: 'sub_total',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
		];

		this.saveHistory('tableItems', 'tableFields');
	}
}
