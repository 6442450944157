
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilter from './ListFilter.vue';
import ListFilterContainer from './ListFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		ListFilter,
	},
})
export default class AreaListFilter extends ListFilterContainer {
	public get options() {
		return gdbx.allAreas;
	}
}
