
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, SalesOrderDetailCard } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import { addComma } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import SalesOrderConversionRateBarChart from '@/components/reports-v2/components/codedWidgets/sales/SalesOrderConversionRateBarChart.vue';

@Component({
	components: {
		TableFilterable,
		TableCollapseToggle,
    SalesOrderConversionRateBarChart,
	},
})
export default class SalesOrderTrackingTable extends FilterWidget {
	@Prop({ default: () => [] }) public selectedAreas!: string[];

	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public validityDays: number = 30;

	public tableItems: Array<{
		doc_no: string;
		doc_date: number;
		customer: string;
		amount: number;
		os_amount: number;
		item: number;
		os_item: number;
		items: any;
	}> = [];
	public tableFields: any[] = [];
	public detailFields: any[] = [];
	public detailFields2: any[] = [];

	public amountSum: number = 0;
	public osAmountSum: number = 0;
	public itemSum: number = 0;
	public osItemSum: number = 0;

	public periodTotalData: any[] = [];
	public periodTotalFields: any[] = [
		{
			key: 'order_amt',
			label: 'Total Amount',
		},
		{
			key: 'os_amt',
			label: 'Total O/S Amount',
		},
		{
			key: 'No_of_os_order',
			label: 'Total Item',
		},
		{
			key: 'No_of_os_customer',
			label: 'Total O/S Item',
		},
	];

	public infoModal = {
		id: 'info-modal2',
		index: 0,
		title: '',
    extras: '',
		amount: '',
		docDate: 0,
		os_amount: '',
		items: [] as any[],
	};

	public format(
		value: number,
		decimal: number = 2,
		forceDecimal: boolean = true,
	) {
		return addComma(value, decimal, forceDecimal);
	}

	public info(item, extras, index, title, amount: any, osAmount, link) {
		this.infoModal.title = title + ' ' + '( ' + item.items[0].customer + ' )';
  this.infoModal.extras = extras;
		this.infoModal.index = index;
		this.infoModal.amount = this.format(Math.abs(amount));
		this.infoModal.os_amount = this.format(osAmount);
		this.infoModal.docDate = item.items[0].docDate;
		this.infoModal.items = item.items;
		this.$root.$emit('bv::show::modal', this.infoModal.id, link);
	}

	public resetInfoModal() {
		this.infoModal.title = '';
  this.infoModal.extras = '';
		this.infoModal.items = [];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Pending Sales Order' + '_' + 'As Of ' + formattedDate;
	}

	public get expensiveHook() {
		const {
			selectedAgents,
			selectedCustomers,
			selectedAsOfDate,
			selectedAreas,
		} = this;
		return JSON.stringify([
			selectedAgents,
			selectedCustomers,
			selectedAsOfDate,
			selectedAreas,
		]);
	}

	public async expensiveCalc() {
		const globalCustomerRef = FilteredDatabase.ref('globalCustomers')
			.customers(this.selectedCustomers)
			.includes('area', this.selectedAreas);

		const filteredAreaCustomers = [
			...(await (await globalCustomerRef.get()).getSet('code')),
		];

		const salesOrderRef = FilteredDatabase.ref('salesOrder')
			.customers(
				filteredAreaCustomers.length > 0
					? filteredAreaCustomers
					: this.selectedCustomers,
			)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		const salesOrderCards: SalesOrderDetailCard[] = await (
			await salesOrderRef.get()
		).getCards();

		const result = {};

		for (const item of salesOrderCards) {
			if (!result[item.docNo]) {
				result[item.docNo] = [];
			}
			result[item.docNo].push(item);
		}

		const beforeResult: any[] = [];

		for (const item in result) {
			if (result.hasOwnProperty(item)) {
				const object = {
					doc_no: '',
					doc_date: 0,
					customer: '',
          extras: '',
					amount: 0,
					os_amount: 0,
					item: 0,
					os_item: 0,
          agent: '',
					items: [] as any[],
          delivery_date: '',
				};

				const cards = result[item] as SalesOrderDetailCard[];

				const itemObject: Array<{
					sequence: number;
					customer: string;
					item: string;
          unit_price: number;
          discount: any;
					amount: number;
					docDate: number;
					os_amount: number;
					quantity: number;
					os_quantity: number;
					_rowVariant: {};
				}> = [];

				const detailObject = cards.map((i) => ({
					sequence: i.sequence,
					customer: gdbx.customerNames[i.code],
					docDate: i.date,
					item: gdbx.stockItemNames[i.itemCode],
          delivery_date: i.outstandingQuantity
              ? moment(i.delivery_date).format('DD/MM/YYYY')
              : null,
          unit_price: i.amount / i.quantity ? i.amount / i.quantity : 0,
          discount: i.discount ? Math.ceil(i.discount) : null,
					amount: i.amount,
					os_amount:
						i.outstandingQuantity === 0
							? 0
							: (i.amount / i.quantity) * i.outstandingQuantity,
					quantity: i.quantity,
					os_quantity: i.outstandingQuantity,
					_rowVariant: i.outstandingQuantity > 0 ? 'top-five' : '',
          agent: i.agent,
				}));

				itemObject.push(...detailObject);

				const amount = cards
					.map((card) => card.amount)
					.reduce((a, b) => a + b, 0);
				const quantity = [...new Set(cards.map((card) => card.sequence))]
					.length;
				const osAmount = cards
					.map((card) =>
						card.quantity === 0
							? 0
							: (card.amount / card.quantity) * card.outstandingQuantity,
					)
					.reduce((a, b) => a + b, 0);

				const osQuantity = [
					...new Set(
						cards
							.filter((card) => card.outstandingQuantity > 0)
							.map((card) => card.sequence),
					),
				].length;

				object.doc_no = item;
				object.doc_date = cards[0].date;
    object.extras = `- ${moment(cards[0].date).format('DD/MM/YYYY')}
						- ${gdbx.customerNames[cards[0].code]}`;
				object.customer = gdbx.customerNames[cards[0].code];
				object.amount = amount;
				object.os_amount = osAmount;
				object.item = quantity;
				object.os_item = osQuantity;
				object.items = itemObject;
    object.agent = cards[0].agent;

				beforeResult.push(object);

			}
		}

		this.tableItems = [...beforeResult.filter((item) => item.os_item > 0)];

		// this.tableItems = this.tableItems.filter((item) => item.osItem > 0);

		this.amountSum = beforeResult.reduce((a, b) => a + b.amount, 0);
		this.osAmountSum = beforeResult.reduce((a, b) => a + b.os_amount, 0);
		this.itemSum = beforeResult.reduce((a, b) => a + b.item, 0);
		this.osItemSum = beforeResult.reduce((a, b) => a + b.osItem, 0);

		this.periodTotalData = [
			{
				order_amt:
					this.currency + ' ' + addComma(this.amountSum, gdbx.numDecimal, true),
				os_amt:
					this.currency +
					' ' +
					addComma(this.osAmountSum, gdbx.numDecimal, true),
				No_of_os_order: this.tableItems.length,
				No_of_os_customer: [...new Set(this.tableItems.map((item) => item.customer))].length,
			},
		];

		this.tableFields = [
			{ key: 'doc_no', sortable: true },
			{
				key: 'doc_date',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'customer',
				sortable: true,
			},
      {
        key: 'os_item',
        label: 'O/S Item',
        sortable: true,
      },
      {
        key: 'item',
        sortable: true,
      },
			{
				key: 'amount',
				label: 'Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'os_amount',
				label: 'O/S Amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},


      {
        key: 'agent',
        label: 'O/S Item',
        sortable: true,
      },
		];

		this.detailFields = [
			{ key: 'sequence', label: 'Seq', sortable: true },
			{
				key: 'item',
				sortable: true,
			},
      {
        key: 'delivery_date',
        sortable: true,
      },
      {
        key: 'os_quantity',
        label: 'O/S Qty',
        sortable: true,
      },
      {
        key: 'quantity',
        label: 'Qty',
        sortable: true,
      },
      {
				key: 'unit_price',
				sortable: true,
        formatter: TableItemFormatter.currency,
			},
      {
				key: 'discount',
				sortable: true,
			},
			{
				key: 'amount',
				label: 'Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'os_amount',
				label: 'O/S Amt',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},


		];

		this.saveHistory('tableItems', 'tableFields');
	}
}
