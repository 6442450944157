
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import QuotationTrackingTable from '@/components/reports-v2/components/codedWidgets/sales/QuotationTrackingTable.vue';
import SalesOrderTrackingTable from '@/components/reports-v2/components/codedWidgets/sales/SalesOrderTrackingTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import AreaListFilter from '@/components/reports-v2/components/filters/AreaListFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';

@Component({
	components: {
		StickyFilterContainer,
		QuotationTrackingTable,
		SalesOrderTrackingTable,
		LastSync,
		AgentsListFilter,
		CustomerListFilter,
		AreaListFilter,
    DateRangeFilter,
	},
})
export default class SalesProgress extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedAgents: string[] = [];
	public selectedCustomers: string[] = [];
	public selectedAreas: string[] = [];

  public manualDateRange: [number, number] = [
    moment().add(-3, 'month').valueOf(),
    moment().valueOf(),
  ];

  public get selectedStockItems() {
    return gdbx.allStockItems;
  }

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).valueOf();
	}

	public get selectedCurrentMonthRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('month').valueOf(),
			moment(this.selectedDay).endOf('month').valueOf(),
		];
	}

	public get currentYearRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

	public get selectedYearToDateRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('month').valueOf(),
		];
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
