
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
  DimensionData,
  PermissionsGroup,
  QuotationDetailCard, SalesOrderDetailCard,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { GobiColor } from '@/helpers/color';
import moment from 'moment/moment';
import {addComma} from '@/util/number';

@Component({
  components: {
    StackedBarChartFilterable,
  },
})
export default class SalesOrderConversionRateBarChart extends FilterWidget {
  public get permissionIds(): PermissionsGroup[] {
    return ['sales'];
  }

  public filterIds: Array<
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = ['dateRange', 'stockItems', 'agents'];

  public xAxisData: string[] = [];
  public finalDatas: Array<{
    name: string;
    openAmount: number;
    convertedAmount: number;
    totalAmount: number;
    conversionRate: number;
  }> = [];
  public legends: string[] = ['Settled Amount', 'Outstanding Amount'];
  public series: echarts.ECharts[] | any = [];

  public get currency() {
    return gdbx.currencySymbol;
  }

  public get dateFormatted() {
    return this.selectedDateRange;
  }

  public get expensiveHook() {
    const { selectedDateRange, selectedAgents, selectedStockItems } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAgents,
      selectedStockItems,
    ]);
  }

  public async expensiveCalc() {
    const globalCustomerRef = FilteredDatabase.ref('globalCustomers')
        .customers(this.selectedCustomers);

    const filteredAreaCustomers = [
      ...(await (await globalCustomerRef.get()).getSet('code')),
    ];

    const salesOrderRef = FilteredDatabase.ref('salesOrder')
        .customers(
            filteredAreaCustomers.length > 0
                ? filteredAreaCustomers
                : this.selectedCustomers,
        )
        .agents(this.selectedAgents)
        .dateAsOf(this.selectedAsOfDate);

    const salesOrderCards: SalesOrderDetailCard[] = await (
        await salesOrderRef.get()
    ).getCards();

    const result = {};

    for (const item of salesOrderCards) {
      if (!result[item.docNo]) {
        result[item.docNo] = [];
      }
      result[item.docNo].push(item);
    }

    const beforeResult: any[] = [];

    for (const item in result) {
      if (result.hasOwnProperty(item)) {
        const object = {
          doc_no: '',
          doc_date: 0,
          customer: '',
          extras: '',
          amount: 0,
          osAmount: 0,
          item: 0,
          osItem: 0,
          items: [] as any[],
          delivery_date: '',
        };

        const cards = result[item] as SalesOrderDetailCard[];

        const itemObject: Array<{
          sequence: number;
          customer: string;
          item: string;
          unit_price: number;
          discount: number;
          amount: number;
          docDate: number;
          osAmount: number;
          quantity: number;
          osQuantity: number;
          _rowVariant: {};
        }> = [];

        const detailObject = cards.map((i) => ({
          sequence: i.sequence,
          customer: gdbx.customerNames[i.code],
          docDate: i.date,
          item: gdbx.stockItemNames[i.itemCode],
          unit_price: i.amount / i.quantity ? i.amount / i.quantity : 0,
          discount: Math.ceil(i.discount),
          amount: i.amount,
          osAmount:
              i.outstandingQuantity === 0
                  ? 0
                  : (i.amount / i.quantity) * i.outstandingQuantity,
          quantity: i.quantity,
          osQuantity: i.outstandingQuantity,
          _rowVariant: i.outstandingQuantity > 0 ? 'top-five' : '',
        }));

        itemObject.push(...detailObject);

        const amount = cards
            .map((card) => card.amount)
            .reduce((a, b) => a + b, 0);
        const quantity = [...new Set(cards.map((card) => card.sequence))]
            .length;
        const osAmount = cards
            .map((card) =>
                card.quantity === 0
                    ? 0
                    : (card.amount / card.quantity) * card.outstandingQuantity,
            )
            .reduce((a, b) => a + b, 0);

        const osQuantity = [
          ...new Set(
              cards
                  .filter((card) => card.outstandingQuantity > 0)
                  .map((card) => card.sequence),
          ),
        ].length;

        object.doc_no = item;
        object.doc_date = cards[0].date;
        object.extras = `- ${moment(cards[0].date).format('DD/MM/YYYY')}
						- ${gdbx.customerNames[cards[0].code]}`;
        object.customer = gdbx.customerNames[cards[0].code];
        object.amount = amount;
        object.osAmount = osAmount;
        object.item = quantity;
        object.osItem = osQuantity;
        object.items = itemObject;
        object.delivery_date = cards[0].delivery_date;

        beforeResult.push(object);

      }
    }

    this.xAxisData = beforeResult
        .filter((a) => a.osAmount)
        .map((item) => item.customer);

    const outstanding = {
      name: 'Settled Amount',
      type: 'bar',
      barWidth: '40%',
      color: GobiColor.COLOR1,
      stack: 'a',
      data: beforeResult.filter((a) => a.osAmount).map((dd) => dd.amount - dd.osAmount),
    };

    const converted = {
      name: 'Outstanding Amount',
      type: 'bar',
      barWidth: '40%',
      color: GobiColor.COLOR2,
      stack: 'a',
      data: beforeResult.filter((a) => a.osAmount).map((dd) => dd.osAmount),
    };

    // const conversionRates = {
    //   name: 'Outstanding Rate (%)',
    //   type: 'bar',
    //   barWidth: '0%',
    //   color: GobiColor.COLOR3,
    //   itemStyle: {
    //     opacity: 0,
    //     color: false,
    //   },
    //   data: beforeResult.map((item) => item.conversionRate),
    // };

    const total = {
      name: 'Total',
      type: 'bar',
      barWidth: '10%',
      color: GobiColor.COLOR4,
      data: beforeResult.filter((a) => a.osAmount).map((dd) => dd.amount),
    };

    this.generateLoadingText(1);

    this.series = [converted, outstanding, total];

    this.saveHistory('series', 'xAxisData', 'finalDatas');
  }
}
